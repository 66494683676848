import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    FormLabel,
    InputLabel,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    LinearProgress,
    FormHelperText
} from '@material-ui/core';

import {
    EmojiObjects

} from '@material-ui/icons';

import {
    yellow

} from '@material-ui/core/colors';

import { Alert } from '@material-ui/lab';

import cities from './cities.json';

import {compare} from './util';

import Feedback from './Feedback.js';


const otherPreferredLocations = [
    {
        "id": 21,
        "label": "APAC",
        "value": "APAC"
    },
    {
        "id": 22,
        "label": "Americas",
        "value": "Americas"
    },
    {
        "id": 23,
        "label": "EMEA",
        "value": "EMEA"
    },
    {
        "id": 24,
        "label": "Remote",
        "value": "Remote"
    }
];

const cityList = [...cities.sort(compare), ...otherPreferredLocations];

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '20ch',
        },
    },
}));


async function postData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(data)
    });
    return response.json();
}

export default function AddProfile(props) {
    const classes = useStyles();
    const [role, setRole] = useState('');
    const [location, setLocation] = useState([]);
    const [postedBy, setPostedBy] = useState();
    const [profileURL, setProfileURL] = useState();
    const [experience, setExperience] = useState();
    const [notification, setNotification] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState();
    const [open, setOpen] = useState(false);
    const expYears = ['Fresher', '1-2 yrs', '3-5 yrs', '5-10 yrs', '10-15 yrs', '> 15 yrs']

    const resetFields = () => {
        setRole('');
        setLocation([]);
        setProfileURL('');
        setPostedBy('');
        setExperience('');
        setSubmitting(false);
        setEmail('');
    }

    const createProfile = async (e) => {
        let formattedLocation = location ? location : 'No preference selected';
        setSubmitting(true);
        e.preventDefault();
        await postData('https://my-mongodb-api-pied.vercel.app/api/request?type=profile', {
            role,
            location: formattedLocation,
            postedBy,
            profileURL,
            experience,
            email,
            addedDate: new Date(Date.now()),
            active: true
        });
        setOpen(true)
        resetFields();
        setNotification(true)
        props.parentCallback();
        

    }

    function handleClose() {
        setOpen(false)
    };

    return (
        <div className="add-profile-container">
            <Feedback open={open} setOpen={handleClose} />
            <FormLabel ><span style={{ paddingLeft: 5, fontWeight: 400, color: 'black' }}>Are you looking out for opportunities? You can post your details.</span></FormLabel>
            <form className={classes.root} id="profile" autoComplete="off" onSubmit={createProfile}>
                <TextField
                    required
                    id="specialization"
                    value={role || ''}
                    label="Specialization"
                    variant="outlined"
                    data-splitbee-event-type="Specialization"
                    data-splitbee-event="Talent Board"
                    onChange={(e) => setRole(e.target.value)}
                    fullWidth
                />
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="preferred-location-input-label">Preferred Location</InputLabel>
                    <Select
                        labelId="preferred-location-select-label"
                        id="preferred-location-select-label-outlined"
                        label="Preferred Location"
                        data-splitbee-event-type="Location"
                        data-splitbee-event="Talent Board"
                        required
                        multiple
                        value={location || ''}
                        onChange={(e) => setLocation(e.target.value)}
                        aria-label="preferred location"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            cityList.map(city => <MenuItem key={city.id} value={city.value}>{city.label}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText><EmojiObjects htmlColor={yellow[700]} /><b>NOTE:</b> You can now choose more than one preferred location.</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="experience-input-label">Experience</InputLabel>
                    <Select
                        labelId="experience-label"
                        id="experience-select"
                        label="Experience"
                        data-splitbee-event-type="Experience"
                        data-splitbee-event="Talent Board"
                        required
                        aria-label="experience-select-options"
                        value={experience || ''}
                        onChange={(e) => setExperience(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            expYears.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <TextField
                    required
                    id="name"
                    value={postedBy || ''}
                    label="Name"
                    data-splitbee-event-type="Name"
                    data-splitbee-event="Talent Board"
                    variant="outlined"
                    onChange={(e) => setPostedBy(e.target.value)}
                    fullWidth
                />

                <TextField
                    required
                    id="email"
                    value={email || ''}
                    label="Email"
                    data-splitbee-event-type="Email"
                    data-splitbee-event="Talent Board"
                    type="email"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
                <TextField
                    required
                    id="profileURL"
                    value={profileURL || ''}
                    label="Linkedin URL"
                    data-splitbee-event-type="Linkedin URL"
                    data-splitbee-event="Talent Board"
                    variant="outlined"
                    helperText={`*Your Linkedin Profile URL is needed to connect with you 🙂`}
                    onChange={(e) => setProfileURL(e.target.value)}
                    fullWidth
                    placeholder="Eg. https://www.linkedin.com/in/john-samuel-1234/"
                />
                <Button
                    variant="contained"
                    color="primary"
                    data-splitbee-event-type={profileURL}
                    data-splitbee-event="Talent Posted"
                    type="submit"
                    style={{ height: 55 }}
                    onSubmit={createProfile}
                >
                    Register
                </Button>
                
            </form>
            
            {notification && props.loading ? <Alert onClose={() => setNotification(false)}>Successfully added the role.</Alert> : false}
            <div style={{maring:20}}>
                {submitting ? <LinearProgress /> : ''}
            </div>
        </div>
    )
}
