import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


import './testimonials.css';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'grid',
//         '& > *': {
//             margin: theme.spacing(1),
//         },
//     },
//     small: {
//         width: theme.spacing(3),
//         height: theme.spacing(3),
//     },
//     large: {
//         width: theme.spacing(15),
//         height: theme.spacing(15),
//     },
// }));

const data = [{
    id: 1,
    content: `It was great thing.I got 2-3 interview opportunity by using this but I didn't selected.But I experienced the interview process and questions what they asked which helpful for me to attend some other interviews.Simply great help from makeadifference`

},
{
    id: 2,
    content: `makeadifference.app is a good website which is used to find job for me. This Platform is makes more usefull for me and my friends to place in good reputed company 👍🏻`

}, {
    id: 3,
    content: `If you're lost your job due to COVID-19 or any other reasons please register in makeadifference.app

    Please utilise this opportunity only if you're laid off from previous organization. If you're looking for better job from current one please don't enroll.`
},
{
    id: 4,
    content: `I also wanted to let you know how much I appreciate you reaching out to me. Definitely makes my day when I see someone else actually cares about freshers getting a job and makes me feel a little less alone in this world. :)`
},
{
    id: 5,
    content: `Thank you. it was very help full for me to got the job at mid level of covid-19`
},
{
    id: 6,
    content: `It made a difference in my career life ❤️ Thanks to you..`
},
{
    id: 7,
    content: `Yes I am working. Thanks and I appreciate what you are doing.`
},
{
    id: 8,
    content: `Yes i have got a job. Thanks for ur support.`
},
{
    id: 9,
    content: `yes i got the job. really thank you`
},
{
    id: 10,
    content: `Thank you i got the offer letter oct 5th is my joining date`
},
{
    id: 11,
    content: `I am really thanks to makeadifference.app. I got 1 interview and I got the job. `
}
]
function Testimonials(props) {
    // const classes = useStyles();

    const renderCard = (item, key) => {
        const { content } = item;
        return (
            <Grid item sm={3}>
                <div className="testimonial-outer-container" >
                    {/* <div className="testimonial-card">
                        <div className={`text-${key}`}>
                            {content}
                            <i className="fas fa-quote-right quote"></i>
                        </div>
                        <div className="footer">
                            <div className="image">
                                <Avatar className={classes.large} />
                            </div>
                        </div>
                    </div> */}
                    <div className="wrapper">
                        <div class='speechbubble'>
                            <p className="content">
                                {content}
                            </p>
                            
                    </div>
                    </div>
                    
                </div>
                
            </Grid>
        )
    }
    return (
        <div style={{marginBottom: 20}}>
        <Typography variant="h4" gutterBottom component={'div'}>
            Testimonials
        </Typography>
        <Grid container spacing={10} justify="left" alignItems="center">
            
            {
                data.map((item, key) => {
                    return (
                        renderCard(item, key)
                    )
                })
            }
        </Grid>
      </div>
    );
}

export default Testimonials;