import React, { useState, useEffect } from 'react';
import './App.css';
import AddProfile from './AddProfile';
import ProfileListing from './ProfileListing';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  
});

const sortDate = (data) => {
  return data.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.addedDate) - new Date(a.addedDate);
    });
};

function Profile() {
  const [jobs, setJobs] = useState();
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(true);
  const classes = useStyles();

  const fetchJobs = async () => {
    setLoading(true);
    await fetch(`https://my-mongodb-api-pied.vercel.app/api/request?type=profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(jsonData => {      
        setJobs(sortDate(jsonData));
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
      })
  }

  useEffect(() => {
    fetchJobs();
  }, [refreshData]);

  const refreshPage = () => {
    setRefreshData(!refreshData);
  }

  return (
    <React.Fragment>
      <AddProfile parentCallback={refreshPage} loading={loading} />  
      <ProfileListing classes={classes} data={jobs} loading={loading} />      
    </React.Fragment>
  );
}

export default Profile;
