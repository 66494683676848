import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './App.css';
import Header from './Header';
import Footer from './Footer';
// import Disclaimer from './Disclaimer';

import Requirement from './Requirement';
import Profile from './Profile';
import FAQ from './FAQ';
import Subscribe from './Subscribe';
import Testimonials from './Testimonials';
// import ProfileAdmin from './admin/ProfileAdmin';
// import Thankyou from './Thankyou';


export default function App() {
  const [jobsCount, setJobsCount] = useState(0);
  const [talentCount, setTalentCount] = useState(0);

  const setPeopleCount = (count) => {
    if (count) {
      setTalentCount(count)
    }
 }

useEffect(() => {
  async function fetchRequirementsCount() {
    await fetch(`https://my-mongodb-api-pied.vercel.app/api/request?type=requirements&action=count`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(jsonData => {      
        setJobsCount(jsonData)
      })
  }

  async function fetchJobsCount() {
    await fetch(`https://my-mongodb-api-pied.vercel.app/api/request?type=profiles&action=count`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(jsonData => { 
        setPeopleCount(jsonData)
        
      })
  }

  fetchJobsCount();
  fetchRequirementsCount();
}, []);

  return (
    <Router>
      <Header jobsCount={jobsCount} talentCount={talentCount} />

      <div>
     
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          
          <Route path="/jobs-board">
            <Requirement />
          </Route>
          <Route path="/post-a-requirement">
            <Redirect to="/jobs-board" />
          </Route>
          <Route path="/talent-board">
            <Profile />
          </Route>
          <Route path="/testimonials">
            <Testimonials />
          </Route>
          <Route path="/profile">
            <Redirect to="/talent-board" />
          </Route>
          {/* <Route path="/profile-admin">
            <ProfileAdmin />
          </Route> */}
          <Route path="/subscribe">
            <Subscribe />
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route path="/jobs-board">
            <Requirement />
          </Route>
          <Route path="/talent-board">
            <Profile />
          </Route>
          <Route path="/testimonials">
            <Testimonials />
          </Route>
          <Route path="/profile">
            <Redirect to="/talent-board" />
          </Route>
          {/* <Route path="/profile-admin">
            <ProfileAdmin />
          </Route>  */}
          <Route path="/">
            <Requirement />
            {/* <Profile/> */}
            {/* <Thankyou /> */}
            {/* <Requirement /> */}
          </Route>
          
        </Switch>
      </div>
      {/* <Disclaimer /> */}
      <Footer />
    </Router>
   
  );
}