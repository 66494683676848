import React from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/QuestionAnswerRounded';
import SubscribeIcon from '@material-ui/icons/EmailOutlined';
import ChatIcon from '@material-ui/icons/TextsmsOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import DeveloperBoardIcon from '@material-ui/icons/LaptopMac';

import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
          },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));




export default function Header(props) {
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;
    const currentPath = pathname;
    const classes = useStyles();

    return (

        <div className={classes.root} style={{ marginBottom: 50 }}>
            <Grid container spacing={4}>

                <Grid item xs={12} sm={5}>
                    <div>
                        <label className="logoTitle">
                            <NavLink to="/">Make A Difference(M.A.D)</NavLink>

                        </label>
                        <div className="logo-title">Covid19 initiative to help people who are looking out for jobs.</div>
                    </div>

                </Grid>

                {/* <div style={{padding: 10}}>
                <Badge color="primary" badgeContent={props.talentCount > 0 ? props.talentCount: null} max={999}>
                    <Button  onClick={() => { history.push('/talent-board') }} 
                    size="medium" 
                    data-splitbee-event-type="talent-board"
                    data-splitbee-event="Navigation"
                    style={
                            currentPath === '/talent-board' || currentPath === '/' ? 
                            {
                                fontWeight: 800,
                                color: 'rgb(51, 51, 51)',
                                borderBottom: '2px solid #1e1f1e',
                                borderRadius: 0
                            } : {}
                    } 
                    
                    >
                        Talent Board
                    </Button>
                    
                    
                    </Badge>
                </div> */}

                <div style={{padding: 10}}>
                <Badge color="primary" badgeContent={props.jobsCount > 0 ? props.jobsCount: null}>
                    <Button onClick={() => { history.push('/jobs-board') }}
                     size="medium"
                     data-splitbee-event-type="jobs-board"
                     data-splitbee-event="Navigation"
                     style={
                        currentPath === '/jobs-board' || currentPath === '/' ?
                        {
                            fontWeight: 800,
                            color: 'rgb(51, 51, 51)',
                            borderBottom: '4px solid #000',
                            borderRadius: 0
                        } : {}
                } 
                    >
                       <DeveloperBoardIcon htmlColor="#000" />{' '} Jobs Board
                    </Button>
                    {/* {props.jobsCount > 0 ? <sup style={{
                        color: 'white',
                        background: 'rgb(21, 101, 192)',
                        padding: 5,
                        borderRadius: '50%',
                        fontWeight: 600
                    }}>{props.jobsCount} </sup>: null} */}
                    
                    </Badge>

                </div>

                <div style={{padding: 10}}>
                  
                <Button onClick={() => { history.push('/faq') }}
                     size="medium"
                     data-splitbee-event-type="faq"
                     data-splitbee-event="Navigation"
                     style={
                        currentPath === '/faq'? 
                        {
                            fontWeight: 800,
                            color: 'rgb(51, 51, 51)',
                            borderBottom: '4px solid #000',
                            borderRadius: 0
                        } : {}
                } 
                    >
                       <HelpIcon htmlColor="#000" />{' '} FAQ
                    </Button>
                   

                </div>


                <div style={{padding: 10}}>
                  
                  <Button onClick={() => { history.push('/testimonials') }}
                       size="medium"
                       data-splitbee-event-type="testimononials"
                       data-splitbee-event="Navigation"
                       style={
                          currentPath === '/testimonials'? 
                          {
                              fontWeight: 800,
                              color: 'rgb(51, 51, 51)',
                              borderBottom: '4px solid #000',
                              borderRadius: 0
                          } : {}
                  } 
                      >
                        <ChatIcon htmlColor="#000" />{' '} Testimonials
                      </Button>
                     
  
                  </div>
                <div style={{padding: 10}}>
                  
                <Button onClick={() => { history.push('/subscribe') }}
                     size="medium"
                     data-splitbee-event-type="subscribe"
                     data-splitbee-event="Navigation"
                     style={
                        currentPath === '/subscribe'? 
                        {
                            fontWeight: 800,
                            color: 'rgb(51, 51, 51)',
                            borderBottom: '4px solid #000',
                            borderRadius: 0
                        } : {}
                } 
                    >
                       <SubscribeIcon htmlColor="#000" />{' '} Subscribe
                    </Button>
                   

                </div>
                <div style={{paddingTop: 16}}>
                  
                    <a id="resume-builder" href="https://buildcv.makeadifference.app/" style={
                        {
                            fontWeight: 800,
                            color: 'rgb(51, 51, 51)',
                            fontSize: '0.875rem',
                            borderRadius: 0,
                            textDecoration: 'none'
                        }
                    } target="_blank" rel="noopener noreferrer"><DashboardIcon htmlColor="#000" />{' '}Resume builder</a>
                   

                </div>

            </Grid>
            

        </div>
    )
}
