import React from 'react'
import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet


Feedback.defaultProps = {
    email: false,
    emailRequired: false,
    emailDefaultValue: "",
    projectName: "Make A Difference",
    subProject: "",
    primaryColor: "#f56147",
    textColor: "#ffffff",
    hoverBorderColor: "#f56147",
    postSubmitButtonMsg: "Thanks 😇🤩!",
    submitButtonMsg: "Send Feedback",
    feedbackTypes: ["general 🙂", "bug 😫", "idea 💡"],
    zIndex: "100000000",
};

export default function Footer() {
    return (
        
        <footer>
            <Feedback 
                projectId="5f36c95ae8160400040d753c"
                data-splitbee-event-type="clicked feedback"
                data-splitbee-event="Feedback"
                />
                <label style={{ fontSize: 11 }}>
                    <span>User generated submissions, not verified. Copyright ©  Make A Difference(M.A.D) | All rights reserved.</span>
                 
                </label> 
                
               
            <a href="https://splitbee.io/?ref=badge" 
            data-splitbee-event-type="clicked splitbee button"
            data-splitbee-event="splitbee"
            >
                    <img className="split-bee-img"
                    src="https://splitbee-cdn.fra1.cdn.digitaloceanspaces.com/static/badge/splitbee-badge-dark.svg" alt="Analytics by Splitbee.io" /> 
            </a>
        </footer>
    )
}
