import React from 'react';
import './App.css';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Button
} from '@material-ui/core';

export default function ProfileListing(props) {
    const { data: jobs, loading } = props;

    function validateLinkedinURL(url) {
        const lowercasedURL = url.toLowerCase();
        let newURL = '';

        if(lowercasedURL.match(/https|http/g)) {
          newURL = lowercasedURL;
        } else if(lowercasedURL.match(/gmail/g)) {
          newURL = `mailto:${lowercasedURL}`;
        } else if(lowercasedURL.match(/linkedin.com/g)) {
          newURL = 'https://'+lowercasedURL;
        } else {
          newURL = lowercasedURL;
        }
        return newURL;
    };

    const columns = [
        {
            name: "role",
            label: "Role",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "location",
            label: "Location",
            options: {
                filter: true,
                sort: false,
                customBodyRender:(location) => {
                    return <>
                     {
                         typeof location === 'object' ? location.join(', ') : location
                     }
                    </>
                }
            }
        },
        {
            name: "experience",
            label: "Experience",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "postedBy",
            label: "Posted By",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "profileURL",
            label: "Profile URL",
            options: {
                filter: false,
                sort: false,
                customBodyRender:(profileURL) => {
                    return <Button variant="contained" color="primary" data-splitbee-event-type={`View profile ${profileURL}`}
                    data-splitbee-event="View Profile - Talent Board">
                                <a className="profile-linkedin-url" 
                                
                                href={validateLinkedinURL(profileURL)} 
                                rel="noopener noreferrer" target="_blank">View Profile</a>
                            </Button>
                  }
            },
            
        }
    ];

    const options = {
        download: false,
        selectableRowsHideCheckboxes: true,
        selectableRowsHeader: false,
        filterType: "multiselect",
        print: false,
        fixedHeader: true,
        fixedSelectColumn: false,
        pagination: true,
        viewColumns: false,
        responsive:"simple"
    };

    return (
        <TableContainer component={Paper}>
        {
            loading ? 
            <div style={{minHeight: 500}}>
                <CircularProgress />
            </div>
           
            :
            <MUIDataTable
                title={"People who are looking out for opportunities."}
                data={jobs}
                columns={columns}
                options={options}
            />
        }
        </TableContainer>
    )
}
