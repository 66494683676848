import React, { useState } from 'react';
import { useDebouncedEffect } from './useDebounceEffect';
import {
    TextField,
    Grid
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

function SearchComponent(props) {
    const [searchValue, setSearchValue] = useState('');

    useDebouncedEffect(() => props.handleSearch(searchValue), 500, [searchValue]);

    const resetSearch = () => {
        setSearchValue('');
    }

    return (
        <Grid container spacing={4} justify="left" alignItems="center">
            <Grid item xs={7} sm={4}>
                <TextField
                    required
                    id="Search"
                    value={searchValue || ''}
                    label="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                    fullWidth
                    InputProps={{endAdornment:
                    <InputAdornment position="end">
                        {searchValue ? <IconButton><ClearIcon onClick={resetSearch}/></IconButton> : null}
                    </InputAdornment>
                    }}
                    
                />
               
            </Grid>
        </Grid>
    );
}

export default SearchComponent;