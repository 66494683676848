import React, {useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
    InputLabel,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    Dialog
} from '@material-ui/core';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: 500
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function Feedback(props) {

  const [text, setText] = useState('')
  const [category, setCategory] = useState('idea');
  const [email, setEmail] = useState('');

  async function postData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(data)
    });
    return response.json();
}

  const submitFeedback = async (e) => {
    e.preventDefault();
    try {
        await postData('https://my-mongodb-api-pied.vercel.app/api/request?type=feedback', {
        text,
        category,
        addedDate: new Date(Date.now()),
        active: true,
        email
        });
        props.setOpen(false);
    } catch (e) {
        props.setOpen(false);
        throw e;
    }
}

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div style={{paddingBottom:0}}>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open} disableBackdropClick={true}>
        <DialogTitle id="customized-dialog-title">
         Your feedback is important to us {' '} <span role="img" aria-label="feedback icon">🙂</span> {' '} 
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
    
            <form id="feedback" autoComplete="off" onSubmit={submitFeedback}>
                <div style={{marginBottom: 20}}>
                    <FormControl variant="outlined" fullWidth >
                        <InputLabel>
                            Category
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            label="Category"
                            data-splitbee-event-type="Category"
                            data-splitbee-event="Feedback"
                            required
                        >
                       
                        <MenuItem value={'issue'} selected><span role="img" aria-label="Issue"> ⚠️</span> {' '}Issue</MenuItem>
                        <MenuItem value={'idea'}><span role="img" aria-label="Idea">💡</span>{' '}Idea</MenuItem>
                        <MenuItem value={'others'}><span role="img" aria-label="hello">👋 </span> {' '} Hi, there</MenuItem>
                        </Select>
                        {/* <FormHelperText>Please do share your feedback/ideas.</FormHelperText> */}
                    </FormControl>
                </div>

                <div style={{marginBottom: 20}}>
                    <TextField
                        required
                        id="text"
                        value={text || ''}
                        label="Text"
                        variant="outlined"
                        data-splitbee-event-type="text"
                        data-splitbee-event="Feedback"
                        onChange={(e) => setText(e.target.value)}
                        fullWidth
                    />
                </div>

                <div style={{marginBottom: 20}}>
                    <TextField
                        id="feedback-email"
                        value={email || ''}
                        label="Email"
                        variant="outlined"
                        data-splitbee-event-type={`feedback email ${email}`}
                        data-splitbee-event="Feedback"
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                    />
                </div>
                
                <Button
                    variant="contained"
                    color="primary"
                    data-splitbee-event-type={'Feedback submitted'}
                    data-splitbee-event="Feedback button"
                    type="submit"
                    onSubmit={submitFeedback}
                >
                    Send feedback
                </Button>
                
            </form>
.
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
                Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
