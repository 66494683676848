import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ShareIcon from '@material-ui/icons/Share';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import CopyToClipboardIcon from '@material-ui/icons/FileCopy';

import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchComponent from './SearchComponent';
import RelativeDate from './RelativeDate';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    title3: {
        fontSize: 9,
        fontWeight: 300
    },
    pos: {
        marginBottom: 12,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function CardListing(props) {
    const classes = useStyles();
    const { data: requirements, loading } = props;

    // const bull = <span className={classes.bullet}>•</span>;

    function validateLinkedinURL(url, email) {
        const mailTo = `mailto: ${email}`;
        const lowercasedURL = url.toLowerCase();
        let newURL = '';

        if (lowercasedURL.match(/https|http/g)) {
            newURL = url;
        } else {
            newURL = <span><EmailOutlined /> <a href={mailTo}>{email}</a><br /></span>
        }
        return newURL;
    };

    const [open, setOpen] = useState(false);
    const [dialogDescriptionContent, setJobDescription] = useState('');
    const [dialogCompanyLocation, setCompanyLocation] = useState('');
    const [dialogJobRole, setJobRole] = useState('');
    const [dialogCompany, setCompany] = useState('');
    // const [dialogAddedDate, setAddedDate] = useState('');
    const [dialogJobURL, setJobURL] = useState('');
    const [copied, setCopied] = useState({});
    const [list, setList] = useState([]);
    const [dialogEmail, setEmail] = useState('')

    const handleClickOpen = (event) => {
        // this.JobDescriptionDialog();
        const { target } = event;
        const { dataset } = target;
        setOpen(true);

        setJobRole(dataset.role);
        setCompanyLocation(dataset.location);
        setJobDescription(dataset.description);
        setCompany(dataset.company);
        // setAddedDate(dataset.addeddate);
        setJobURL(dataset.joburl);
        setEmail(dataset.email);

    };

    const handleCopyClipboard = (e, id) => {

        setCopied({ [id]: true });
    }

    const truncate = (input) => input.length > 80 ? `${input.substring(0, 80)}...` : input;


    const handleClose = () => {
        setOpen(false);
        setJobDescription('');
        setCompanyLocation('');
        setJobRole('');
        setCompany('');
        // setAddedDate('');
        setJobURL('');
    };

    const handleSearch = (value) => {
        const formattedValue = value !== '' ? value.toLowerCase() : value;
        const filteredResult =  requirements
                                    .filter(entry => Object.values(entry)
                                    .some(val => JSON.stringify(val).toLowerCase().includes(formattedValue)));

        setList(filteredResult)
    };


    useEffect(() => {
        setList(props.data);
    }, [props]);

    return (

        <Grid container spacing={4}>
            
            {loading ?
                <div style={{minHeight: 500}}>
                    <CircularProgress />
                </div>
                :

                <>
                    <Grid item xs={12} md={12}>
                         {list && list.length ? <SearchComponent handleSearch={handleSearch} /> :
                          <div style={{fontSize: 20, display: 'grid', justifyContent: 'center'}}>
                                Stay tuned... Job requirements will be updated here soon. <br />
                          </div>} 
                    </Grid>
                    {list && list.length ? <Grid item xs={12} md={12} style={{textAlign: 'left', fontSize: 12, fontWeight: 600 }}>
                        {`Showing ${list && list.length} of ${props.data && props.data.length} results`}
                    </Grid> : null }
                    
                  {
                      list && list.map((requirement, key) => {
                        const {
                            companyName,
                            role,
                            location,
                            description,
                            jobURL,
                            addedDate,
                            email
                        } = requirement;
    
                        const jobApplicationLink = validateLinkedinURL(jobURL, email);
                        const emailBody = `
                                        Dear friend 👋🏽, \n
                                        Please find the job vacancy position in ${companyName} \n
                                        Detailed information about the job 🚀. \n
                                        Company Name 📂: \n\n ${companyName} \n\n
                                        Job Description 📝: \n\n ${description} \n\n
                                        Job Role 🔖: \n\n ${role} \n\n
                                        Job Location 🏢: \n\n ${location}\n\n
                                        Job Application Link 🔗: \n\n ${jobApplicationLink} \n\n
                                        For more information, please visit the website 👉🏽 makeadifference.app
    
                                        Regards.
                                        `;
const clipboardBody = `
Dear friend 👋🏽, \n
Please find the job vacancy position in ${companyName} \n
Detailed information about the job 🚀. \n
Company Name 📂: \n\n ${companyName} \n\n
Job Description 📝: \n\n ${description} \n\n
Job Role 🔖: \n\n ${role} \n\n
Job Location 🏢: \n\n ${location}\n\n
Job Application Link 🔗: \n\n ${jobApplicationLink} \n\n
For more information, please visit the website 👉🏽 makeadifference.app

Regards.
`;
                        const emailSubject = `Job opening in ${companyName} for the role ${role} - Referral from makeadifference.app`;
    
                        return (
                            <Grid item xs={12} md={3}>
    
    
    
    
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant="body1" className={classes.title} color="primary" gutterBottom>
                                            <b>{role}</b> <br />
                                        </Typography>
                                        <Typography variant="body1" component="h2" className={classes.title} >
                                            <b>Company Name:</b> <br /> {companyName} <br />
                                        </Typography>
    
    
                                        <Typography variant="body1" className={classes.title} color="textSecondary">
                                            <br /> <b>Job location:</b> <br />{typeof location === 'object' ? <div dangerouslySetInnerHTML={{ __html: location.join(', ') }} /> : location}
                                        </Typography>
                                        <Typography variant="body1" component="p" noWrap>
                                            <br />
                                            <span className="truncate"
    
                                                title={description} >
                                                <b className={classes.title}>Job Description</b> <br />

                                                {/* {jobApplicationLink} */}
                                                {!jobURL.toLowerCase().match(/https|http/g) ? jobApplicationLink : null }
    
                                                <span className={classes.title}>{truncate(description)} </span>
    
                                            </span>
                                            <br />
                                            {
                                                description.length > 50 ?
    
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        data-company={companyName}
                                                        data-location={location}
                                                        data-role={role}
                                                        data-description={description}
                                                        data-addedDate={addedDate}
                                                        data-jobURL={jobURL}
                                                        data-email={email}
                                                        onClick={handleClickOpen}
                                                    >
                                                        more info.
                                        </Link> : null
                                            }
                                            
                                            <div
    
    
                                            >
                                               <br /> <RelativeDate date={addedDate} />
    
    
                                            </div>
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
    
                                        <IconButton aria-label="Apply for job">
                                            <Button variant="contained" color="primary" 
                                                data-splitbee-event-type={`Apply for job ${jobURL}`}
                                                data-splitbee-event="Jobs Board Apply"
                                            >
                                                <a href={validateLinkedinURL(jobURL, email)} 
                                                    rel="noopener noreferrer external" 
                                                    
                                                    target="_blank">
                                                    Apply
                                                </a>
                                            </Button>
                                        </IconButton>
                                        <IconButton aria-label="share">
                                            <Tooltip title="Share job info" aria-label="Share job info" placement="top">
                                                <a 
                                                data-splitbee-event-type={`Share job info - ${emailSubject}`}
                                                data-splitbee-event="Jobs Board Share"
                                                href={`mailto:?subject=${emailSubject}

                                    
                                    &body=${encodeURIComponent(emailBody)}
                                    `}><ShareIcon htmlColor="#000" /></a>
                                            </Tooltip>
                                           
                                        </IconButton>
                                        {/* <Typography component="span" className={classes.title3} >Share job info</Typography> */}
                                        <IconButton aria-label="Copy to clipboard">
                                            <Tooltip title="Copy to clipboard" aria-label="Copy to clipboard" placement="top">
                                                <CopyToClipboard text={(clipboardBody)}
                                                    data-splitbee-event-type="Copy to clipboard"
                                                    data-splitbee-event="Jobs Board Copy"
                                                    onCopy={(event) => handleCopyClipboard(event, key)}>
                                                    <CopyToClipboardIcon htmlColor="#000" />
                                                </CopyToClipboard>
                                            </Tooltip>
                                            
                                            {
                                                copied[key] ?
                                                    <Typography component="span" className={classes.title3} >Copied</Typography>
                                                    : null
                                            }
                                        </IconButton>
                                        {/* <Typography component="span" className={classes.title3} >Copy to clipboard</Typography> */}
                                    </CardActions>
                                </Card>
    
    
    
                            </Grid>
                        )
                    })
                  }
                </>
                
                
            }
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title-label"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">

                    <Typography variant="h6" component="h6">
                        Job Requirement for {dialogCompany}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-content-text" component={'div'}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle2" component="h6">
                                Company Name
                                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component={'div'}
                            >
                                {dialogCompany}
                            </Typography>

                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle2" component="h6">
                                Location
                                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component={'div'}
                            >

                                {dialogCompanyLocation}
                            </Typography>

                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle2" component="h6">
                                Job Role
                                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component={'div'}
                            >

                                {dialogJobRole}
                            </Typography>

                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle2" component="h6">
                                Description
                                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component={'div'}
                            >

                                {dialogDescriptionContent}
                            </Typography>

                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Typography variant="subtitle2" component="h6">
                                Job Application Link
                                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                component={'div'}
                            >
                                <a href={validateLinkedinURL(dialogJobURL, dialogEmail)} rel="noopener noreferrer external" target="_blank">{dialogJobURL}</a>
                            </Typography>

                        </Grid>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
