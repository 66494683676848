import React, { useState, useEffect } from 'react';
import './App.css';
import AddRequirement from './AddRequirement';
import CardListing from './CardListing';
// import RequirementListing from './RequirementListing';


const sortDate = (data) => {
  return data.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.addedDate) - new Date(a.addedDate);
    });
};

function Requirement() {
  const [requirements, setRequirements] = useState();
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(true);

  const fetchRequirements = async () => {
    setLoading(true);
    await fetch(`https://my-mongodb-api-pied.vercel.app/api/request?type=requirement`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(jsonData => {      
        setRequirements(sortDate(jsonData));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      })
  }

  useEffect(() => {
    fetchRequirements();
  }, [refreshData]);

  const refreshPage = () => {
    setRefreshData(!refreshData);
  }

  return (
    <React.Fragment>
      <AddRequirement parentCallback={refreshPage} loading={loading} />  
      <CardListing data={requirements} loading={loading} />
      {/* <RequirementListing data={requirements} loading={loading} /> */}
    </React.Fragment>
  );
}

export default Requirement;
