import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IntlRelativeFormat from 'intl-relativeformat'

const useStyles = makeStyles({
    
    title3: {
        fontSize: 13,
        fontWeight: 500,
        color: 'blue'
    }
});

function RelativeDate(props) {
    const classes = useStyles();
    return (
        <div>
           
            <Typography component="span"  className={classes.title3}> Posted {IntlRelativeFormat('en').format(new Date(props.date))}</Typography>
        </div>
    );
}

export default RelativeDate;