import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    FormLabel,
    InputLabel,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    LinearProgress,
    FormHelperText
} from '@material-ui/core';

import cities from './cities.json';

import { Alert } from '@material-ui/lab';

import {compare} from './util';

import SendIcon from '@material-ui/icons/ArrowRightAltRounded';

import {
    yellow

} from '@material-ui/core/colors';

import {
    EmojiObjectsOutlined

} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '20ch',
        },
    },
}));

const otherLocations = [
    {
        "id": 21,
        "label": "APAC",
        "value": "APAC"
    },
    {
        "id": 22,
        "label": "Americas",
        "value": "Americas"
    },
    {
        "id": 23,
        "label": "EMEA",
        "value": "EMEA"
    },
    {
        "id": 24,
        "label": "Remote - India",
        "value": "Remote - India"
    },
    {
        "id": 25,
        "label": "Remote - USA",
        "value": "Remote - USA"
    },
    {
        "id": 26,
        "label": "Remote - Anywhere",
        "value": "Remote - Anywhere"
    }
];

const cityList = [...cities.sort(compare), ...otherLocations];

async function postData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(data)
    });
    return response.json();
}

export default function AddRequirement(props) {
    const classes = useStyles();
    const [companyName, setCompanyName] = useState('');
    const [location, setLocation] = useState([]);
    const [description, setDescription] = useState();
    const [role, setRole] = useState();
    const [jobURL, setJobURL] = useState('');
    const [notification, setNotification] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');

    const resetFields = () => {
        setCompanyName('');
        setLocation([]);
        setDescription('');
        setJobURL('');
        setRole('');
        setSubmitting(false);
        setEmail('');
    }

    const createRequirement = async (e) => {
        let formattedLocation = location ? location : 'No preference selected';
        setSubmitting(true);
        e.preventDefault();
        await postData('https://my-mongodb-api-pied.vercel.app/api/request?type=requirement', {
            companyName,
            location: formattedLocation,
            description,
            jobURL,
            role,
            email,
            addedDate: new Date(Date.now()),
            active: true
        });

        resetFields();
        setNotification(true);
        props.parentCallback();
        
    }



    return (
        <div style={{ marginBottom: 50 }}>
            
            <FormLabel ><span style={{paddingLeft:5, fontWeight: 400, color: 'black'}}>Is your company hiring? You can post your requirements.</span></FormLabel>
            <form className={classes.root} id="jobs" autoComplete="off" onSubmit={createRequirement}>
                <TextField 
                    required 
                    id="company-name" 
                    value={companyName || ''} 
                    label="Company Name" 
                    data-splitbee-event-type="Company Name"
                    data-splitbee-event="Jobs Board"
                    variant="outlined" 
                    onChange={(e) => setCompanyName(e.target.value)} 
                />
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="location-input-label">Location</InputLabel>
                    <Select
                        labelId="location-select-label"
                        id="location-select-label-outlined"
                        label="Location"
                        data-splitbee-event-type="Location"
                        data-splitbee-event="Jobs Board"
                        required
                        value={location || []}
                        onChange={(e) => setLocation(e.target.value)}
                        multiple
                        aria-label="location"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            cityList.map(city => <MenuItem key={city.id} value={city.value}>{city.label}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText><EmojiObjectsOutlined htmlColor={yellow[700]} /><b>NOTE:</b> You can now choose more than one preferred location.</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    id="job-role" 
                    value={role || ''} 
                    label="Job role" 
                    data-splitbee-event-type="Job role"
                    data-splitbee-event="Jobs Board"
                    variant="outlined" 
                    onChange={(e) => setRole(e.target.value)} 
                />
                <TextField 
                    required 
                    id="job-description" 
                    value={description || ''} 
                    label="Job description" 
                    data-splitbee-event-type="Job description"
                    data-splitbee-event="Jobs Board"
                    variant="outlined" 
                    rowsMax={1}
                    onChange={(e) => setDescription(e.target.value)} 
                />

                <TextField
                    id="email"
                    value={email || ''}
                    label="Email"
                    data-splitbee-event-type="Email"
                    data-splitbee-event="Jobs Board"
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
                
                <TextField 
                    required 
                    id="job-application-link" 
                    value={jobURL || ''} 
                    label="Job Application Link"
                    data-splitbee-event-type="Job Application Link"
                    data-splitbee-event="Jobs Board"
                    variant="outlined" 
                    helperText={`* Job application link / Email / Link to Linkedin post.  🙂`}
                    onChange={(e) => setJobURL(e.target.value)} 
                />
                <Button 
                    variant="contained" 
                    color="primary" 
                    type="submit" 
                    data-splitbee-event="Posted Requirement"
                    onSubmit={createRequirement}>
                     Post Requirement 
                     {/* <SendIcon htmlColor="#fff" /> */}
                </Button>
            </form>
            {notification && props.loading ? <Alert onClose={() => setNotification(false)}>Successfully added.</Alert>: false} 
            <div style={{maring:20}}>
                {submitting ? <LinearProgress /> : ''}
            </div>
        </div>
    )
}
